.projects {
  padding: 0 100px;
  display: grid;
  grid-gap: 15px;
}

.projects img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
}
.project-img-wrapper {
  position: relative;
  height: 100%;
}
.project-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(105, 105, 105, 0.8);
  color: white;
  opacity: 0;
}
.project-img-overlay:hover {
  opacity: 1;
  transition: 0.5s ease-in-out;
}
.project-title,
.project-description {
  margin-left: 100px;
}
.project-title {
  margin-top: 100px;
}
.project-description {
  font-family: "Quicksand", sans-serif;
  max-width: 75%;
}
.md-project-title {
  visibility: hidden;
  max-height: 0;
}
@media (max-width: 991px) {
  .md-project-title {
    visibility: visible;
    max-height: 100%;
    text-align: right;
    margin-top: 15px;
  }
  .project-title-md h3 {
    font-size: 30px;
  }
  .project-description-md h4 {
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }
  .project-img-wrapper {
    height: auto;
  }
  .project-img-overlay,
  .project-img-overlay:hover {
    display: none;
  }
}

@media (max-width: 600px) {
  .projects {
    padding: 0 40px;
  }
  .project-title-md h3 {
    text-align: center;
    font-size: 20px;
  }
  .project-description-md h4 {
    font-size: 15px;
    text-align: center;
  }
}
