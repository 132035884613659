nav {
  padding: 40px !important;
}
nav .container-fluid {
  justify-content: space-between !important;
}
nav .collapse {
  justify-content: flex-end;
}

nav img {
  width: 100px;
}
nav i {
  font-size: 45px;
  padding: 15px 0;
}

@media (max-width: 991px) {
  nav .navbar-nav {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
