.project-highlight .carousel {
  width: 60vw;
  margin: auto;
  padding: 20px;
}
.project-highlight img {
  width: 100%;
  border-radius: 20px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

h3 {
  font-family: "TAN Meringue", sans-serif;
  font-size: 40px;
}
span,
span a {
  font-family: "Quicksand", sans-serif;
  text-decoration: none;
  color: black;
  font-size: 35px;
}

@media (max-width: 800px) {
  .project-highlight .carousel {
    width: 100%;
    margin: auto;
    padding: 20px;
  }
  .project-highlight img {
    width: 100%;
    border-radius: 20px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .project-highlight .d-none {
    display: block !important;
  }
  .project-highlight h3 {
    font-size: 30px;
  }
  span,
  span a {
    font-size: 25px;
  }
}
