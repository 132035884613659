.skill-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}
.skill-set h3 {
  margin-bottom: 30px;
}

.skill-icons span {
  font-size: 20px;
}

@media (max-width: 800px) {
  .skill-icons {
    grid-template-columns: 1fr 1fr;
  }
  .skill-set h3 {
    font-size: 30px;
  }
  .skill-icons span {
    font-size: 12px;
  }
}
