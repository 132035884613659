.contact-page {
  min-height: 100vh;
}
.contact {
  text-align: center;
  padding-top: 180px;
  padding-bottom: 20px;
}

.contact h3,
.contact h4 {
  font-family: "TAN Meringue", sans-serif;
  font-size: 40px;
}

.contact h4 {
  color: white;
  text-decoration: none;
  margin: 0;
}
.contact button {
  border: none;
  background: none;
}
.contact button i {
  font-size: 50px;
}
.contact .email {
  background-color: black;
  padding: 20px;
}

.contact i {
  font-size: 80px;
  color: black;
}

.contact .social-icons {
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 800px) {
  .contact h3 {
    font-size: 30px;
  }
  .contact h4 {
    font-size: 20px;
  }
  .contact i {
    font-size: 60px;
  }
  .contact button i {
    font-size: 40px;
  }
}
