@font-face {
  font-family: "TAN Meringue";
  src: url("./assets/fonts/TAN\ -\ MERINGUE.ttf"),
    url("./assets/fonts/TAN\ -\ MERINGUE.woff");
}

.App {
  margin: 0 auto;
  width: 100vw;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

::-webkit-scrollbar {
  display: none !important;
}

nav {
  background-color: white;
}
