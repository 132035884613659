.hero {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
}
.logo {
  width: 350px;
}
.home h1 {
  font-family: "Quicksand", sans-serif;
  font-size: 50px;
  max-width: 40vw;
}
.container-fluid {
  justify-content: end !important;
}

.home i {
  font-size: 45px;
  padding: 15px 0;
}
section {
  padding: 80px;
  text-align: center;
}
@media (max-width: 800px) {
  .hero {
    flex-direction: column;
    padding: 40px;
    justify-content: space-evenly;
  }
  section {
    padding: 40px;
  }
  .logo {
    width: 300px;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home h1 {
    font-size: 35px;
    max-width: 80vw;
    text-align: center;
  }
  .home .navbar-nav {
    flex-direction: row;
  }
  .home i {
    padding: 0 15px;
  }
}
